<template>
  <div style="background-color: white; height: 100vh; overflow: hidden;">
    <div v-if="type == 1" style=" height: 100vh; overflow-y: scroll;margin: 0.5rem;">
      <div style="width: 100%;text-align: center;font-weight: 600; font-size: 1.1rem;"> 《用户协议》 </div>
      <p class="title">一、服务协议的确定和接纳 </p>
      <p class="context">
        十节优选（以下简称本平台）是由好礼知十节（北京）科技有限公司及其关联公司（以下简称本公司）向本公司用户提供的福利平台服务。本公司的服务协议（以下简称本协议）内容包括协议正文及所有好礼知十节（北京）科技有限公司及其关联公司已经发布的或将来可能发布的各类规则。所有规则为协议不可分割的一部分，与协议正文具有同等法律效力。本站的各项电子服务的所有权、运营权及有关权力归好礼知十节（北京）科技有限公司及其关联公司。所有用户在使用好礼知十节（北京）科技有限公司提供的各项服务的同时，承诺接受并遵守各项相关规则的规定。好礼知十节（北京）科技有限公司及其关联公司有权根据需要不定时地制定、修改本服务协议或各类规则。经修订的协议在平台公布后，立即生效，同时亦成为本服务协议的一部分。用户登录或继续使用本服务将表示用户已接受经修订的协议及规则。用户确认和接纳本服务协议后，本服务协议即在用户和好礼知十节（北京）科技有限公司之间及其关联公司产生法律效力。请用户务必在注册之前认真阅读全部服务协议内容，如有任何疑问，可向好礼知十节（北京）科技有限公司咨询。
      </p>
      <p class="context">本协议中客户是指向好礼知十节（北京）科技有限公司及其关联公司直接购买产品或服务（含卡券等）的单位。 </p>
      <p class="context">本协议中的用户是指本平台注册用户，本平台为注册用户提供产品或服务（含卡券等），相应的注册用户应按照本协议行使或履行相关的权利和义务。 </p>
      <p class="context">
        本协议中的异常用户指被投诉、举报、或使用行为异常的用户，包括不限于：用户账号存在归属多个不同客的情况、用户绑定卡券归属多个不同客户、用户频繁绑定卡券、用户绑定较多数量卡券、用户消费异常、用户频繁集中兑换特殊商品、用户倒卖等行为。
      </p>
      <p class="context">当用户提供在客户单位在职证明、工牌（如已离职，可提供离职证明）、赠送人在职证明、赠送人工牌、对公邮件说明等，也包括客户明确出具的授权证明等，以证明用户已获本公司客户的授权，有权使用本公司卡券。
      </p>
      <p class="context">在使用十节优选各项卡券或服务前，请您务必仔细阅读，在确认充分理解并同意后再开始使用： </p>
      <p class="context">1）无论用户事实上是否在注册之前认真阅读了本服务协议，只要用户勾选的“我已阅读并同意”并点击注册按钮按照好礼知十节（北京）科技有限公司注册程序成功注册为用户，即表示您同意并签署了本服务协议。
      </p>
      <p class="context">
        2）使用本网站下订单，您应具备购买相关商品的权利能力和行为能力，如果您在18周岁以下，您需要在监护人的监护参与下才能注册并使用本网站。在下订单的同时，即视为您满足上述条件，并对您在订单中提供的所有信息的真实性负责。 </p>
      <p class="title">二、用户信息提供 </p>
      <p class="context">
        用户注册时，为保障用户的合法权益，避免在服务时因用户注册资料与真实情况不符而发生纠纷，请用户注册时务必按照真实、全面、准确的原则填写。不断更新注册资料，符合及时详尽、准确、真实的要求。注册用户时务必需要提供真实的手机号码，如由于用户误填写而造成电子码类产品或票据无法发送至指定手机所产生的损失由用户自行承担。
      </p>
      <p class="title">三、用户资料隐私保护 </p>
      <p class="context">1）本协议所称之用户隐私包括被法律确认为隐私内容，并符合下述范围的信息： </p>
      <p class="context">（a）用户注册十节优选时根据网站要求提供的个人信息。 </p>
      <p class="context">（b）在用户访问网站网页，使用十节优选服务时，网站自动接收并记录的用户浏览器上的服务器数据，包括但不限于IP地址、网站Cookie中的资料及用户要求取用的网页记录。 </p>
      <p class="context">（c）十节优选通过合法途径从商业伙伴处取得的用户个人资料。 </p>
      <p class="context">2）保护用户隐私是十节优选的一项基本政策，十节优选保证不对外公开或向第三方提供用户注册资料及用户在使用网络服务时存储在十节优选的非公开内容，但下列情况除外： </p>
      <p class="context">（a）事先获得用户的明确授权； </p>
      <p class="context">（b）根据有关的法律法规要求； </p>
      <p class="context">（c）根据行政或司法机构的要求； </p>
      <p class="context">（d）为维护社会公众的利益； </p>
      <p class="context">（e）为维护十节优选的合法权益。 </p>

      <p class="context">3）十节优选可能会与第三方合作向用户提供相关服务，在此情况下，如第三方同意承担与本公司保护用户隐私的同等责任，则本公司可将用户信息提供给该第三方。 </p>
      <p class="context">4）用户须做到: </p>
      <p class="context">（a）用户名和昵称的注册与使用应符合网络道德，遵守中华人民共和国的相关法律法规； </p>
      <p class="context">（b）用户名和昵称中不能含有威胁、淫秽、漫骂、非法、侵害他人权益等有争议性的文字。 </p>
      <p class="title">四、用户资料安全 </p>
      <p class="context">
        1）用户务必对其密码、个人账号等信息保密，以免被盗用或遭窜改。注册成功后，用户必须保护好自己的账号和密码，对非因本公司原因的泄露本公司不承担任何责任。用户若发现任何非法使用用户账号或安全漏洞的情况，应当立即与本公司联系，如因用户自身原因造成其账户无法正常使用及遭受损失，一切损失由用户自行承担。
      </p>
      <p class="context">
        2）用户不得通过不正当手段恶意注册账号，不得盗用他人账号，不得泄露其他用户的相关信息。如用户违反上述规定，本公司有权直接采取一切必要的措施，包括但不限于删除用户发布的内容、暂停或查封用户账号，取消因违规所获利益，乃至通过诉讼形式追究用户法律责任等。
      </p>
      <p class="title">五、用户的权利和义务 </p>
      <p class="context">
        1）用户理解:本公司仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如电脑、调制解调器及其他与接入互联网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由用户自行承担。 </p>
      <p class="context">2）用户须对其账户中的所有行为、活动和事件负全责。 </p>
      <p class="context">3）作为用户您理解并认可：本公司主营业务为向企业提供一站式多品类弹性福利解决方案，本公司的客户仅为企事业单位及其他组织。同时，十节优选平台仅为通过正规途径取得本公司卡券的用户提供服务。 </p>
      <p class="context">4）本公司的卡券禁止二次销售，本公司有权冻结非正规途径获取的本公司卡券，任何单位或个人不得通过非正规途径购买本公司卡券，否则产生的纠纷和损失由用户自行承担。 </p>
      <p class="context">
        5）作为用户，您理解并同意，本公司在与本公司客户系基于对客户员工福利的保障，而达成的产品销售协议或行为。同时，双方也明确，本公司产品（含卡券）的权益归属于本公司客户，有权使用人仅限于本公司客户、客户员工、受赠人（赠送人为客户员工）或客户授权人。为保障本公司客户的利益，本公司有权暂停异常用户产品（含卡券）的使用，用户在取得本公司客户有权使用人证明并向本公司提供后，产品（含卡券）可恢复正常使用，其中，有权使用人证明包括:在本公司客户单位的在职证明、工牌、离职证明、赠送人在职证明、赠送人离职证明、赠送人工牌、对公邮件说明等，也包括客户明确出具的授权证明等。
      </p>
      <p class="context">6）作为用户，您理解并同意，一个有权使用人提供的证明只能用于一个用户账户。 </p>
      <p class="context">
        7）如果十节优通过公司风控机制或其他方式发现或收到他人举报投诉用户违反本协议约定或存在任何恶意行为的或违反本协议行为的，十节优选有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规账号处以包括但不限于警告、限制或禁止使用部分或全部功能、账号封禁、冻结、注销等处罚。
      </p>
      <p class="context">
        8）由于市场变化及各种以合理商业努力难以控制因素的影响，本网站无法避免您提交的订单信息中的商品出现缺货、价格标示错误等情况；如您下单所购买的商品出现以上情况，用户及十节优选均有权自行取消订单，已完成支付的用户，本公司将为其办理退付手续。
      </p>
      <p class="title">六、风控规则 </p>
      <p class="context">
        本公司与客户系基于对客户员工福利的保障，而达成的卡券销售协议或行为，故十节优选平台有权及有义务保障客户的福利为本公司客户的员工所享受。据此，您理解并同意本公司可根据本公司风控机制确认的行为识别异常用户，本公司有权暂停异常用户卡券的使用，并视行为情节对违规帐号采取包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁、冻结、注销、限制兑换范围等措施。
      </p>
      <p class="context">被采取以上措施的用户在取得本公司客户盖章的在职证明、离职证明、赠送人在职证明赠送人离职证明或本公司客户对公邮箱确认的信息并提交本公司后，卡券可恢复正常使用。 </p>
      <p class="context">本公司根据风控逻辑确认的行为所识别的用户为异常用户，该行为包括如下情形： </p>
      <p class="context">1）异常大额兑换； </p>
      <p class="context">2）异常连续兑换； </p>
      <p class="context">3）兑换不符合用户正常消费习惯； </p>
      <p class="context">4）绑定手机号异常，如非实名制手机号、批量号码等； </p>
      <p class="context">5）绑卡行为异常，如绑定较多归属于不同客户卡券； </p>
      <p class="context">6）绑卡设备异常，如多个账号拥有相互关联的设备标识； </p>
      <p class="context">7）十节优选平台业务合作方投诉的涉嫌黑灰产业务的高风险账号； </p>
      <p class="context">8）其他异常账户、绑卡和消费行为。 </p>
      <p class="title">七、申诉途径 </p>
      <p class="context">
        涉及到账号被警告、限制或禁止使用部分或全部功能、账号封禁、冻结、注销的申诉时，本公司可能会要求您提交身份信息或有权使用人证明以备核查；如涉及卡券产品异常导致暂停使用、卡券延期等涉及卡券问题的申诉，您需提供相应的证明，如您是有权使用人，您需要向我司提供有权使用人证明，如您的产品为受赠产品，您需要提供赠送人的在职证明或工牌至本公司，否则您的申诉将无法予以处理。申诉请拨打400-001-6621，或向网站客服进行反映。
      </p>
      <p class="title">八、服务的变更、中断和中止 </p>
      <p class="context">本公司享有变更、中断或终止本服务的权利。本公司保留根据实际情况随时调整十节优选平台提供的服务种类形式的权利。本公司不承担因业务调整给用户造成的损失。情况包括但不限于： </p>
      <p class="context">1）本公司有随时中止或终止与影院以及演出主办方合作的权利； </p>
      <p class="context">2）本公司有随时调整服务费收取标准的权利； </p>
      <p class="context">3）本公司有由于影院系统、第三方系统、服务器故障等原因，为用户调换座位、场次或影院的权利； </p>
      <p class="context">4）本公司有按照退票原则为用户退票的权利； </p>
      <p class="context">5）本公司有更改产品使用权限的权利； </p>
      <p class="context">6）本公司有更换购票流程、购票方式、取票流程的权利； </p>
      <p class="context">7）用户同意本公司可随时行使以上变更权，并接受变更后所提供的服务。 </p>
      <p class="title">九、退换货原则 </p>
      <p class="context">1）由于票品类商品存在时效性，因此即便您在十节优选网站对相应商品支付了点数、款项，但仍有可能无法获得该票品，如发生此情况十节优选将在五个工作日内为您安排退点、退款。 </p>
      <p class="context">
        2）因票品具有唯一性，对售出的票进行退换将直接影响演出项目主办方、剧场方和其他观众的权益，任何一方均无权利单方面作出退换行为。票品一经售出，概不退换（因不可抗力导致演出取消、演出时间和内容变更等情况除外）。 </p>
      <p class="context">
        3）由于在线选座的电影票均为十节优选的签约影院或第三方的系统销售，票和座位都具有唯一性。对售出的票进行退换将直接影响到影院和其他观众的权益，任何一方均无权利单方面做出退换行为。一旦影院系统或第三方系统已出票，十节优选则不接受任何退款和换票行为。
      </p>
      <p class="context">4）如遇票务信息需要确认但无法联系到用户导致无法出票时，三个工作日后均视为退票处理。 </p>
      <p class="context">5）其他退换货原则具体详见商品详情页，请用户下单时仔细查看商品详情页中的要求，否则导致的损失，由用户自行承担。 </p>
      <p class="title">十、配送原则 </p>
      <p class="context">实体类商品需要配送时，请清楚准确地填写收件人的真实姓名、送货地址及联系方式。因如下列情况造成订单延迟或无法配送等，本公司将不承担迟延配送的相关责任： </p>
      <p class="context">1）客户提供错误信息和不详细的地址； </p>
      <p class="context">2）票务送达无人签收，由此造成的重复配送所产生的费用及相关的后果； </p>
      <p class="context">3）不可抗力，例如:自然灾害、交通戒严、突发战争等。 </p>
      <p class="title">十一、网络服务内容的所有权 </p>
      <p class="context">
        本服务协议定义的网络服务内容包括:文字、软件、声音、图片、录像、图表、广告中的全部内容；电子邮件的全部内容；本公司为用户提供的其它信息。所有这些内容受版权、商标标签和其它财产所有权法律的保护。所以，用户只能在本公司和广告商授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的衍生产品。本公司所有的文章版权归原文作者和本公司共同所有，任何人需要转载本站的文章必须征得原文作者和本公司授权。
      </p>
      <p class="title">十二、责任限制 </p>
      <p class="context">
        如因不可抗力或其它本公司无法控制的原因使本系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，本公司不承担任何责任，但本公司会尽可能合理地协助处理善后事宜，并努力使客户免受经济损失。 </p>
      <p class="context">用户明确理解和同意，本公司不对因下述任一情况而导致的任何损害赔偿承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其它无形损失的损害赔偿（无论本公司是否已被告知该等损害赔偿的可能性）：
      </p>
      <p class="context">1）第三方未经批准的接入或第三方更改用户的传输数据或数据； </p>
      <p class="context">2）第三方对“服务”的声明或关于“服务”的行为；或非因本平台的原因而引起的与“服务”有关的任何其它事宜，包括疏忽； </p>
      <p class="context">3）用户因在演出场馆、影院受到意外伤害引起纠纷追究责任的，本公司不承坦任何责任。 </p>
      <p class="title">十三、法律管辖适用及其他 </p>
      <p class="context">
        1）本协议的订立、执行和解释及争议的解决均应适用中国法律。如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，您同意并接受通过向北京仲裁委员会在北京提起仲裁的方式来寻求解决方案。 </p>
      <p class="context">2）如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。 </p>
      <p class="title">十四、其他 </p>
      <p class="context" style="margin-bottom: 1.5rem;">如您对本协议或本服务有意见或建议，可按照本协议申诉途径中注明的联系方式与本公司联系，我们会给予必要的解释和帮助。</p>

    </div>
    <div v-if="type == 2" style=" height: 100vh; overflow-y: scroll;margin: 0.5rem;">
      <div style="width: 100%;text-align: center;font-weight: 600; font-size: 1.1rem;margin-bottom: 1rem;"> 《用户隐私政策》 </div>

      <p class="context">
        十节优选是好礼知十节（北京）科技有限公司（以下简称：本公司）旗下企业弹性福利平台，专业向企业提供一站式多品类弹性福利解决方案，助力企业员工关怀、优化员工福利品质，提升员工幸福感。本隐私权政策适用于本公司及关联公司提供的所有产品和服务，旨在向您说明我们在您使用我们的产品或服务时如何收集、使用保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。
        本隐私政策将帮助您了解以下内容：
      </p>
      <p class="title">一、收集和使用个人信息 </p>
      <p class="title">二、Cookies </p>
      <p class="title">三、共享、转让和公开披露 </p>
      <p class="title">四、个人信息保护措施 </p>
      <p class="title">五、个人信息的管理 </p>
      <p class="title">六、政策更新与通知 </p>
      <p class="title">七、联系我们 </p>
      <p class="context">
        在使用本公司各项产品或服务前，请您务必仔细阅读本政策，在确认充分理解并同意后再开始使用。如对本政策内容有任何疑问、意见或建议您可通过本政策“联系我们”所提供的联系方式与我们联系。
      </p>
      <p class="title">一、收集和使用个人信息 </p>
      <p class="context">
        在您使用我们的产品或服务时，我们需要或可能需要收集和使用的您的个人信息包括如下两种：
      </p>
      <p class="context">
        1.为实现向您提供我们产品或服务的基本功能，您须授权我们收集、使用的必要信息。如您拒绝提供相应信息，您将无法正常使用我们的产品或服务；
      </p>
      <p class="context">
        2.为实现向您提供我们产品或服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品或服务的基本功能。
      </p>
      <p class="context">
        您理解并同意：
      </p>
      <p class="context">
        1.因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品或服务范围存在差异，相应地基本或附加功能及收集使用的个人信息类型、范围等会有所区别，请以具体的产品或服务功能为准；
      </p>
      <p class="context">
        2.我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您得同意后收集、使用。
      </p>
      <p class="context">
        在此过程中，如果您有任何疑问、意见或建议，您可通过本政策“联系我们”提供的联系方式与我们联系，我们会尽快为您作出解答。我们会为实现本政策下述的各项功能，收集和使用您的个人信息：
      </p>
      <p class="title">
        (一) 帮助您成为我们的用户
      </p>
      <p class="context">
        1.基础会员用户
      </p>
      <p class="context">
        我们通过十节优选网站账户为您提供基础用户服务。为注册成为用户并使用我们的用户服务，您需要提供手机号码用于创建十节优选网站账户。如果您仅需使用浏览服务，您不需要注册成为我们的用户及提供上述信息。
      </p>
      <p class="context">
        对于需要通过十节优选网站账户才能使用的服务，我们可能会根据您提供的上述信息校验您的用户身份，确保我们是在为您本人提供服务
      </p>
      <p class="context">
        地域服务：基于十节优选网站产品的地域性，为方便您更便捷的享受到浏览、使用、兑换本地产品等服务，我们会为您提供定位服务。当您同意本协议后，我们将收集您使用十节优选网站时所处的地理位置，为您推荐所在区域可以购买的商品或服务。需要您了解和知悉的是，我们仅收集您当时所处的地理位置但不会将您各时段的位置信息进行结合以判断您的行踪轨迹。
      </p>
      <p class="context">
        授权登录：我们可能会根据您的授权从第三方处获取您的第三方账户信息，并与您的十节优选账户进行绑定，使您可通过第三方账户直接登录、使用我们的产品与/或服务。我们将在您授权同意的范围内使用您的相关信息。
      </p>
      <p class="context">
        2.账户信息展示
      </p>
      <p class="context">
        如果您已拥有十节优选账户，我们可能会在十节优选服务中显示您的上述个人信息，包括通过十节优选账户集中展示您的个人资料、交易订单。
      </p>
      <p class="title">
        (二) 帮助您完成下单及订单管理
      </p>
      <p class="context">
        当您在十节优选订购具体商品与/或服务时，我们会通过系统为您生成购买该商品或服务的订单。在下单过程中，您至少需要提供您的收货人姓名、收货地址、收货人联系电话、也可能需要提供身份证号码信息、照片，同时该订单中会载明您所购买的商品与/或服务信息、具体订单号、订单创建时间、您应支付的金额。我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客服与售后服务及其他我们明确告知的目的。
      </p>
      <p class="context">
        部分栏目（项目）涉及到您或您为其他人订购商品或服务，请您下单时仔细阅读该项目下相关授权协议，下单即代表您同意该协议的内容，我们将根据该协议的内容收集和使用您的相关信息。
      </p>
      <p class="context">
        为便于您了解查询订单信息并对订单信息进行管理，我们会收集您在使用我们服务过程中产生的订单信息。
      </p>
      <p class="title">
        (三) 帮助您完成支付
      </p>
      <p class="context">
        在您预订和下单后，您可以选择与我们合作的第三方支付机构（例如微信支付等支付通道，以下称“支付机构”）所提供的支付服务，我们会将您的十节优选账户用户名、对应的支付账户用户名、订单支付相关信息、订单安全相关设备信息及其他反洗钱法律要求的必要信息与支付机构共享。
      </p>
      <p class="context">
        为使我们及时获悉并确认您的支付进度及状态，为您提供售后与争议解决服务，您同意我们可自支付机构处收集与支付进度相关的信息。
      </p>
      <p class="title">
        (四) 帮助您完成商品或服务的交付
      </p>
      <p class="context">
        为保证您购买的商品与/或服务能够顺利、安全、准确送达我们会向为十节优选提供物流配送服务的第三方配送公司（包括顺丰、EMS、圆通、申通、中通、韵达、天天快递、京东快递等）披露订单相关配送信息。您知晓并同意相应物流配送主体不可避免地获知及使用您的配送信息，用于完成交付目的。如果您拒绝共享此类信息，我们将无法完成相关交付服务。
      </p>
      <p class="context">
        为使我们及时获悉并确认交付进度及状态，向您提供售后与争议解决服务，您同意我们可自物流服务主体处收集与交付进度相关的信息。
      </p>
      <p class="title">
        (五) 帮助您的企业完成福利行为
      </p>
      <p class="context">
        请您了解并理解，一般情况下十节优选的服务基于您所属企业为您提供福利行为，为保证您能够正常在十节优选享受企业福利，您的所属企业可能会需要十节优选向其提供您的姓名、电话、邮箱、生日、性别、国籍、账户金额、已使用金额、账户余额、兑换（交易）明细、物流单号、货物送货情况。
      </p>

      <p class="context">
        为使我们能为您提供福利兑换服务，您同意我们可根据您所属企业要求，向其提供此类信息。
      </p>
      <p class="title">
        (六) 客服及争议处理
      </p>
      <p class="context">
        当您与我们联系或提出售中售后服务、争议纠纷处理申请时为了保障您的账户及系统安全，我们需要您提供必要的个人信息以核验您的用户身份。
      </p>
      <p class="context">
        为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信或通话记录及相关内容（包括账户信息、订单信息、您为了证明相关事实提供的其他信息、您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账户信息和订单信息。
      </p>
      <p class="context">
        为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息、您参与问卷调查时向我们发送的问卷答复信息。
      </p>
      <p class="title">
        (七) 其他
      </p>
      <p class="context">
        1.若您提供的信息中含有其他用户的个人信息，在向十节优选提供这些个人信息之前，您需确保您已经取得合法的授权。若其中涉及儿童个人信息的，您需在发布前取得对应儿童监护人的同意，前述情形下监护人有权通过本政策“联系我们”所提供的反馈渠道联系我们，要求更正或删除涉及儿童个人信息的内容。
      </p>
      <p class="context">
        2.征得授权同意的例外，您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意，且我们可能不会响应您提出的修改、删除、注销、撤回同意、索取信息的请求：
      </p>
      <p class="context">
        (1)与国家安全、国防安全有关的；
      </p>
      <p class="context">
        (2)与公共安全、公共卫生、重大公共利益有关的；
      </p>
      <p class="context">
        (3)与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；
      </p>
      <p class="context">
        (4)出于维护您或其他个人的生命、财产等重大合法权益但又难以得到本人同意的；
      </p>
      <p class="context">
        (5)您自行向社会公众公开的个人信息；
      </p>
      <p class="context">
        (6)依法公开披露的信息中收集的个人信息，如合法的新闻报道、政府信息公开等渠道；
      </p>
      <p class="context">(8)用于维护所提供的产品、或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障等；
      </p>

      <p class="context">(9)为合法的新闻报道所必需的；
      </p>

      <p class="context">(10)学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
      </p>

      <p class="context">
        (11)法律法规规定的其他情形请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的使用无需另行向您通知并征得您的同意。
      </p>

      <p class="context">
        3.如我们停止运营十节优选产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对我们所持有的与已关停业务相关的个人信息进行删除或匿名化处理。涉及儿童个人信息的，我们会并将停止运营的通知及时告知儿童监护人。；
      </p>

      <p class="title">二、Cookie
      </p>

      <p class="context">为确保网站正常运转、使您获得更轻松的访问体验，我们会在您的计算机或移动设备上存储Cookie、Flash
        Cookie或浏览器（或关联应用程序）提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储（统称“Cookie”）。借助于Cookie，网站能够存储你的登录信息，购物车内的商品等数据。
      </p>

      <p class="title">三、共享、转让和公开披露
      </p>

      <p class="title">(一) 共享
      </p>

      <p class="context">我们不会与十节优选平台以外的公司、组织和个人共享您的个人信息，但以下情况除外：
      </p>
      <p class="context">1.在法定情形下的共享：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
      </p>
      <p class="context">2.在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
      </p>
      <p class="context">3.在您主动选择情况下共享：您通过十节优选购买商品或服务，我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给相关商品或服务的提供者，以实现您的交易及售后服务需求。
      </p>
      <p class="context">
        4.与关联公司间共享：为便于我们基于十节优选账户向您提供产品或服务、识别用户账户异常、保护十节优选关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司与/或其指定的服务提供商共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
      </p>
      <p class="context">
        5.与授权合作伙伴共享：我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能。我们仅会出于本隐私权政策声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。对于涉及儿童个人信息的，我们不允许合作伙伴进行转委托。
      </p>
      <p class="context">
        目前，我们的授权合作伙伴主要包括:供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴。这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查等。
      </p>
      <p class="title">(二) 转让
      </p>
      <p class="context">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
      </p>
      <p class="context">1.在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。
      </p>
      <p class="context">
        2.在十节优选平台发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
      </p>

      <p class="title">(三) 公开披露
      </p>

      <p class="context">我们仅会在以下情况下，公开披露您的个人信息：
      </p>

      <p class="context">1.获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息。
      </p>

      <p class="context">
        2.如果我们确定您出现违反法律法规或严重违十节优选相关协议及规则的情况，或为保护十节优选用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或在征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及十节优选已对您采取的措施。
      </p>

      <p class="title">(四) 共享、转让、公开披露个人信息时事先征得授权同意的例外
      </p>
      <p class="context">以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
      </p>
      <p class="context">1.与国家安全、国防安全有关的；
      </p>
      <p class="context">2.与公共安全、公共卫生、重大公共利益有关的；
      </p>
      <p class="context">3.与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；
      </p>
      <p class="context">4.出于维护您或其他个人的生命、财产等重大合法权益但又难以得到本人同意的；
      </p>
      <p class="context">5.您自行向社会公众公开的个人信息；
      </p>
      <p class="context">6.从合法公开披露的信息中收集的个人信息，如合法的新闻报道、政府信息公开等渠道。
      </p>
      <p class="context">请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意
      </p>
      <p class="title">四、个人信息保护措施
      </p>
      <p class="context">
        (一)我们只会因本政策所述目的在所需的期限内保留您的个人信息，除非法律有强制的存留要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。而我们判断前述期限的标准包括：
      </p>
      <p class="context">1.完成与您相关的交易目的、维护相应交易及业务记录、应对您可能的查询或投诉；
      </p>
      <p class="context">2.保证我们为您提供服务的安全和质量；
      </p>
      <p class="context">3.您是否同意更长的留存期间；
      </p>
      <p class="context">4.是否存在保留期限的其他特别约定。
      </p>
      <p class="context">在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。
      </p>
      <p class="context">(二)互联网并非绝对安全的环境，使用十节优选服务时我们强烈建议您不要使用非十节优选推荐的通信方式发送您的信息。
      </p>
      <p class="context">
        在使用十节优选服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或联系地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。请使用复杂密码，协助我们保证您的账户安全。请注意，您在使用我们服务时自愿共享甚至公开分享的信息可能会涉及您或他人的个人信息甚至个人敏感信息。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。
      </p>
      <p class="title">五、个人信息的管理
      </p>
      <p class="context">您可以通过以下方式访问及管理您的信息：
      </p>
      <p class="title">(一) 查询、修改、补充和删除您的信息
      </p>
      <p class="context">您有权查询、更正或补充您的信息。您可以通过以下方式自行操作：
      </p>
      <p class="context">1.账户信息：如果您希望访问或编辑您的账户中的个人基本信息（如密码等），您可以登录平台执行此类操作；
      </p>
      <p class="context">2.订单信息：您可以通过访问“我的订单”页面查看您的所有订单状态。
      </p>
      <p class="title">(二) 注销您的账户
      </p>
      <p class="context">请您了解并同意，在您从所属企业离职时，所属企业可能会帮您注销您的账户。
      </p>
      <p class="title">六、政策更新与通知
      </p>
      <p class="context">
        为给您提供更好的服务以及随着十节优选业务的发展，本隐私权政策也会随之更新、优化。我们会通过在十节优选网站等关联平台发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问十节优选以便及时了解最新的隐私政策。在您选择同意之时，即视为您已同意本政策的全部内容及修订后的全部内容。
      </p>
      <p class="title">七、联系我们
      </p>
      <p class="context" style="margin-bottom: 1rem;">如有任何疑问、意见或建议，您可通过十节优选客服电话400-001-6621与我们联系，我们将在15天内回复您的请求。
      </p>


    </div>

  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      type: 1,
    }
  },
  created() { },
  components: {},
  methods: {},
  mounted() {
    this.type = parseInt(this.$route.query.type)
    if (this.type == 1) {
      document.title = '用户协议'
    } else
      document.title = '用户隐私政策'
  },
}

</script>
<style  lang="scss" scoped>
.context {
  text-indent: 20px; //缩进了20px
  color: #666;
  font-size: 0.8rem;
  line-height: 1.5;
}

.title {
  
  font-size: 1rem;
  font-weight: 600;
  margin: 0.5rem 0 0.5rem 0;
}
</style>